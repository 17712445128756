$border: 7px;

.mrspin {
	.banner {
		.banner-text {
			align-items: center;
		}
	}
	.steps {
		.step {
			display: flex;
			align-items: center;
			margin: 10px 0;

			.number {
				position: relative;
				color: #fff;
				font-weight: bold;
				font-size: 36px;
				background: #082836;
				width: 64px;
				height: 64px;
				background-clip: padding-box;
				border: $border solid #ffdd4a;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
				flex-grow: 0;
			}

			.text {
				display: flex;
				flex-direction: column;
				font-size: 1.5em;
			}
		}
	}
}
