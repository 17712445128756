.header {
	background-color: #156da0;
	margin-top: 75px;

	.logo {
		position: relative;
		text-align: center;
		padding: 3px 0;

		img {
			height: 60px;
		}

		.plus18-logo {
			position: absolute;
			top: 0;
			right: 0;
			padding: 5px;

			img {
				height: 48px;
			}
		}
	}
}
