$border: 3px;

.betneptune {
	.banner {
		.banner-text {
			align-items: center;
		}
	}
	.steps {
		.step {
			display: flex;
			align-items: center;
			margin: 10px 0;

			.number {
				position: relative;
				color: #fff;
				font-weight: bold;
				font-size: 36px;
				background: linear-gradient(to bottom, #084de1, #7796ea, #0518c0);
				width: 64px;
				height: 64px;
				background-clip: padding-box;
				border: $border solid #fff;
				border-radius: 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
				flex-grow: 0;
			}

			.text {
				display: flex;
				flex-direction: column;
			}
		}
	}
}
