.banner {
	height: 500px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;

	.banner-text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		font-weight: bold;
		text-shadow: 0 0 1em #000;
		color: #fff;

		&.no-shadow {
			text-shadow: none;
		}

		p {
			margin-block: 10px;
			padding: 0;
		}

		.big {
			font-size: 2em;
		}
		.bigger {
			font-size: 3em;
		}
		.huge {
			font-size: 4em;
		}
	}
}
