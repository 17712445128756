.amazon-slots {
	.banner {
		.banner-text {
			align-items: center;
			.offerImageBlock {
				text-align: center;
				width: 100%;

				img {
					width: 55%;
				}
			}
		}
	}

	.steps {
		.step {
			display: flex;
			align-items: center;
			margin: 10px 0;

			.number {
				position: relative;
				color: #37465a;
				font-weight: bold;
				font-size: 36px;
				background: #ffda00;
				width: 64px;
				height: 64px;
				background-clip: padding-box;
				border-radius: 50vw;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
				flex-grow: 0;
				border-color: #fff;
			}

			.text {
				display: flex;
				flex-direction: column;
			}
		}
	}
}
