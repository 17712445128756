$white: #fff;
$yellow: hsl(54, 100%, 62%);
$yellowHover: hsl(54, 100%, 80%);
$grey: #494949;
$lightGrey: #9e9e9e;
$purple: #ab47bc;
$blue: #2196f3;
$green: #4caf50;
$orange: #ff9800;
$brown: #d1a447;
$gold: #fc6;
$goldHover: #ffbf40;
$pink: #fe368a;
$pinkHover: #fe68a7;

.text-white {
	color: $white !important;
}

.text-yellow {
	color: $yellow;
}
.yellow {
	background-color: $yellow !important;
	&:hover {
		background-color: $yellowHover !important;
	}
}

.text-grey {
	color: $grey !important;
}
.text-grey-light {
	color: $lightGrey !important;
}

.text-blue {
	color: $blue;
}

.text-green {
	color: $green !important;
}
.green {
	background-color: $green !important;
}

.text-orange {
	color: $orange !important;
}
.orange {
	background-color: #ff9800 !important;
}

.text-brown {
	color: $brown !important;
}
.brown {
	background-color: $brown !important;
}

.bold {
	font-weight: bold;
}
.uppercase {
	text-transform: uppercase;
}
.center {
	text-align: center;
}

.gold {
	background-color: $gold !important;
	&:hover {
		background-color: $goldHover !important;
	}
}
.pink {
	background-color: $pink !important;
	&:hover {
		background-color: $pinkHover !important;
	}
}
