a {
	color: #039be5;
	text-decoration: none;
}

#root {
	display: flex;
	height: 100vh;
	flex-direction: column;

	header,
	main,
	footer {
		display: block;
	}

	main {
		flex: 1 0 auto;
	}
}
