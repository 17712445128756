.page-footer {
	background-color: #156da0;
	color: #fff;

	.warn,
	.copyright {
		text-align: center;
		padding: 1em;
		font-size: 14px;
	}

	.copyright {
		background-color: rgba(51, 51, 51, 0.08);
		color: rgba(255, 255, 255, 0.8);
		text-align: center;
		padding: 1em;
	}
}
