.gala-bingo {
	.banner {
		.banner-text {
			align-items: flex-start;

			.bigger {
				font-style: italic;
				color: #ffe330 !important;
				font-weight: bold;
			}

			.big {
				font-style: italic;
				color: #082ba6;
				font-weight: bold;
			}
		}
	}
	.steps {
		background-color: #1463eb;
		.step {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin: 10px 0;
			padding-top: 1rem;
			padding-bottom: 1rem;

			.text {
				display: flex;
				flex-direction: column;

				.step-header {
					color: #fdd941 !important;
					font-style: italic;
					font-size: 1.4rem;
				}
			}
		}
	}

	.content {
		color: #122268;

		a {
			color: #ec028d;
			font-weight: bold;
		}
	}
}
