.gala-spins {
	.banner {
		.banner-text {
			align-items: flex-start;
		}
	}
	.steps {
		.step {
			display: flex;
			align-items: center;
			margin: 10px 0;

			.number {
				position: relative;
				color: #fff;
				font-weight: bold;
				font-size: 36px;
				background: #ed6c7d;
				width: 64px;
				height: 64px;
				background-clip: padding-box;
				border-radius: 50vw;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
				flex-grow: 0;
			}

			.text {
				display: flex;
				flex-direction: column;
			}
		}
	}
}
