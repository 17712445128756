.foxy-games {
	.banner {
		background-position: top;

		.banner-text {
			align-items: center;
		}
	}
	.steps {
		.step {
			display: flex;
			align-items: center;
			margin: 10px 0;
			background-color: #0a258b;
			padding-top: 1rem;
			padding-bottom: 1rem;
			&:nth-child(2) {
				border-left: 1px solid #2e459c;
				border-right: 1px solid #2e459c;
			}

			.number {
				position: relative;
				color: #f7b334;
				font-weight: bold;
				font-size: 36px;
				background: transparent;
				width: 64px;
				height: 64px;
				background-clip: padding-box;
				border: none;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
				flex-grow: 0;
			}

			.text {
				display: flex;
				flex-direction: column;
			}
		}
	}
}
