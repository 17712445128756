$border: 3px;

.steps {
	.step {
		display: flex;
		align-items: center;
		margin: 10px 0;

		.number {
			position: relative;
			color: #2c2c2c;
			font-weight: bold;
			font-size: 36px;
			background: linear-gradient(to bottom, #e1a408, #ead677, #c05a05);
			width: 64px;
			height: 64px;
			background-clip: padding-box;
			border: $border solid #2c2c2c;
			border-radius: 15px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			flex-grow: 0;
		}

		.text {
			display: flex;
			flex-direction: column;
		}
	}
}
